var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{staticClass:"grey lighten-4",attrs:{"id":"affiliates","space":"36"}},[_c('v-container',[_c('base-section-heading',{attrs:{"title":_vm.$ml.get('direction_investment')}}),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("../../assets/it.png"),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"280px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$ml.get('it').toUpperCase()))])],1),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black v-card--reveal white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(_vm.$ml.get('it_desc'))+" ")]):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("../../assets/nedv.jpg"),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"280px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$ml.get('real_estate').toUpperCase()))])],1),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black v-card--reveal white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(_vm.$ml.get('real_estate_desc'))+" ")]):_vm._e()])],1)]}}])})],1)],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("../../assets/selhoz.jpg"),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"280px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$ml.get('agriculture').toUpperCase()))])],1),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black v-card--reveal white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(_vm.$ml.get('agriculture_desc'))+" ")]):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("../../assets/mashinostr.png"),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"280px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$ml.get('mechanical_engineering').toUpperCase()))])],1),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black v-card--reveal white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(_vm.$ml.get('mechanical_engineering_desc'))+" ")]):_vm._e()])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
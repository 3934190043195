<template>
  <base-section id="affiliates" class="grey lighten-4" space="36">
    <v-container>
      <base-section-heading :title="$ml.get('direction_investment')" />

      <v-row cols="12">
        <v-col md="6">
          <v-hover v-slot="{ hover }">
            <v-card class="mx-auto">
              <v-img
                src="../../assets/it.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="280px"
              >
                <v-card-title>{{$ml.get('it').toUpperCase()}}</v-card-title>
              </v-img>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out black v-card--reveal white--text"
                  style="height: 100%"
                >
                {{$ml.get('it_desc')}}
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col md="6">
          <v-hover v-slot="{ hover }">
            <v-card class="mx-auto">
              <v-img
                src="../../assets/nedv.jpg"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="280px"
              >
                <v-card-title>{{$ml.get('real_estate').toUpperCase()}}</v-card-title>
              </v-img>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out black v-card--reveal white--text"
                  style="height: 100%"
                >
                  {{$ml.get('real_estate_desc')}}
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row cols="12">
        <v-col md="6">
          <v-hover v-slot="{ hover }">
            <v-card>
              <v-img
                src="../../assets/selhoz.jpg"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="280px"
              >
                <v-card-title>{{$ml.get('agriculture').toUpperCase()}}</v-card-title>
              </v-img>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out black v-card--reveal white--text"
                  style="height: 100%"
                >
                  {{$ml.get('agriculture_desc')}}
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col md="6">
          <v-hover v-slot="{ hover }">
            <v-card>
              <v-img
                src="../../assets/mashinostr.png"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="280px"
              >
                <v-card-title>{{$ml.get('mechanical_engineering').toUpperCase()}}</v-card-title>
              </v-img>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out black v-card--reveal white--text"
                  style="height: 100%"
                >
                  {{$ml.get('mechanical_engineering_desc')}}
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionDirections",
  data() {
    return {
      reveal: false,
    };
  },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6!important;
  position: absolute;
  width: 100%;
  padding: 15px;
}
</style>